function instantiateUploader(elem, audiofile_field_callback_name){
	//elem: the element to make into an uploadifive field
	//audiofile_field_callback_name: the full name of the hidden field that will be created to store the callback value. defaults to "audiofile_id"
	if(typeof audiofile_field_callback_name == 'undefined'){
		//if no callback name was provided, just add a new [audiofile_id] post index
		audiofile_field_callback_name = $(elem).attr('id') + "[audiofile_id]";
	}

	//console.log("entered instantiateUploader with element id: "+$(elem).attr('id')+" and callback: "+audiofile_field_callback_name);

	// insert a new upload queue element
	$(elem).before("<div id='" + $(elem).attr('id') + "[queue]'></div>");

	//insert a new hidden field for the callback of the created audio file id
	if(!$('#'+sanitizeSelector(audiofile_field_callback_name)).length ){
		$(elem).after("<input type='hidden' name='" + audiofile_field_callback_name + "' id='" + audiofile_field_callback_name+"' value='0' />");
	}

	//console.log('we are registering uploadify on element named'+$(elem).attr('id'));

	$(elem).uploadifive({
		'auto'             : true,
		'formData'         : {
								'_token' : $('meta[name="csrf-token"]').attr('content')
		                     },
		'fileType': 'audio/mpeg',
		'queueID'          : sanitizeSelector($(elem).attr('id')+"[queue]"),
		'uploadScript'     : '/ajax/uploadFile',
		'onUploadStart' : function(file) {
                            console.log(file);
					        },
		'buttonText' : 'Select .mp3',
		'buttonClass' : 'upload-button',
		'buttonCursor' : 'pointer',
		'onUploadComplete' : function(file, data) {
            // read returned json, set hidden element to value of audiofile_id in return object
			response = JSON.parse(data);
			filename = response.audiofile_filename;
			$("#"+sanitizeSelector(audiofile_field_callback_name)).val(response.audiofile_id);

			//console.log("setting audiofield callback ("+audiofile_field_callback_name+")to: "+response.audiofile_id);
			//console.log("returned filename: "+filename);
			if(filename != 'undefined'){
				//remove existing audio player when new file was added
				$("#"+sanitizeSelector(audiofile_field_callback_name)).siblings('.preview').remove();

				//build new audio tag
				$("#"+sanitizeSelector(audiofile_field_callback_name)).after('<audio class="preview" controls=""><source src="/assets/audio/'+filename+'" type="audio/mpeg"></audio>');

			}
		}
	});

}

function sanitizeSelector(id){
	//takes string representing a DOM id, returns same string with escape characters for square brackets
	var returnstring = '';

	if(typeof id != 'undefined'){
		id = id.replace(/\[/g, "\\[");
		id = id.replace(/\]/g, "\\]");
		returnstring = id;
	}

	return returnstring;
}

function getWhispersForm(){
	console.log("fired getwhispersform...");

	if($('.campaignSelect').length){

		var screenerParam = '';
		if($('#screener_id').length){
			screenerParam = '/'+$('#screener_id').val()
		}

		$.ajax({
				  type: "POST",
				  url: '/ajax/whisperPromptsForm/'+$('.campaignSelect').val()+screenerParam,
				  dataType: 'json'
			})
			.done(
				function(data){
					if(data.success){
						//successful validation.
						//load up returned view in the whispers form area.
						console.log(data.whispersform);

						$('.whispersForm .col-50').html(data.whispersform);
						$('.whispersForm .uploadifive').each(function(index){
							instantiateUploader($(this));
						});
						$('.accordion').accordion();
					}
					else{
						//we did not pass validation. output messages and scroll to the top
						$('.alerts').hide();
						$('.alerts').html(data.error);
						$('html, body').animate({ scrollTop: 0 }, 0, function(){ $('.alerts').fadeIn(); } );

					}
				}
			);
	}
}

function copyToClipboard() {
  // For IE.
  if (window.clipboardData) {
    window.clipboardData.setData("Text", input.val());
  } else {
    // Lets copy.
    document.execCommand ("copy", false, null);

  }
}

//===============================================================================
//=================			SCREENER  JS			=============================
//===============================================================================
function addQuestion(){
		//clone question template, perform string parsing for indices
		//NOTE: these string replacements with length number are 1 less than you'd think, because the template questions and answers count as 1 that need to be subtracted
		//swap in dynamic question number
		//swap in dynamic question number + 1

		var newQuestion = $('#questionTemplate').clone().html()
							.replace( /\[n\]/g, $('#accordion .screener-question').length)
							.replace( /\[n\+1\]/g, $('#accordion .screener-question').length+1);

		// console.log("addQuestion entered! newquestion is "+ newQuestion);
		newQuestion = addAnswer($(newQuestion));
		newQuestion = addAnswer($(newQuestion));


		// inject cloned question with instantiated items into accordion
		$('#accordion').append('<h6></h6>')
						.append($(newQuestion));

		var qNum = $('.screener-question').length-1;
		//update all goto question dropdowns with new value.
		$('.questionSelect').append($("<option></option>", {
				value: qNum,
        		text : 'Question ' + qNum
			}));

		//does this work?
		//$('#accordion').accordion('refresh');
		$('.accordion').accordion({heightStyle: 'content', collapsible: true});

		//or this?
		$("#accordion").accordion('destroy').accordion({
			active: -1,
			heightStyle: 'content',
			collapsible: true,
		});

		//instantiate uploader in question.
		instantiateUploader($(newQuestion).find('.uploadifive'));

		//set default state values
		$(newQuestion).find('.answersHolder').hide();
		$(newQuestion).find('.dependsOnResponse').hide();

		console.log("update step headers from addquestion calling now:");
		updateStepHeaders();

		if(qNum > 2){
			//only apply scrolling after we add the two default call steps.
			$('html, body').animate({
			    scrollTop: $("#questionsContainer .ui-accordion-header:last").offset().top - 100
			 }, 500);
		}
}

function updateStepHeaders(){
	//iterate over every call step and update its header in the accordion.
	$( "#accordion .screener-question" ).each(function( index ) {
		//console.log("iterating a screener question. html: "+$(this).html());
		console.log("iterating header index number "+index);
		var questionDOM = $('#accordion .screener-question:eq('+index+')');
	  	$(questionDOM).prev('h6').html(questionAccordionHeader(index+1));
	});
}

function questionAccordionHeader(n){
	var header = '';

	if(n){
		var stepText = '';
		var questionDOM = ''

		//get current question DOM
		questionDOM = $('#accordion .screener-question').eq(n-1);
		// console.log("questionAccordionHeader called with n="+n);
		// console.log("questionDOM: "+$(questionDOM).html());
		// console.log("questionlabel count: "+$(questionDOM).find('.questionLabel').length);
		// console.log("questionAccordionheader "+n+" questionlabel: "+$(questionDOM).find('.questionLabel').first().val());

		//build the step description
		stepText += '<div class="inline step-label">';
		if($(questionDOM).find('.questionLabel').first().val() !== ''){
			stepText += $(questionDOM).find('.questionLabel').first().val()+'.';
		}
		stepText += '</div>';
		stepText += '<div class="inline question-type">'+$(questionDOM).find('.questionType option:selected').first().text()+'</div>';
		stepText += ' <div class="inline then">Then</div> ';
		stepText += '<div class="inline question-action">'+$(questionDOM).find('.questionThen option:selected').first().text()+'</div>';

		//build the final header tag
		header = '<span class="ui-accordion-header-icon ui-icon ui-icon-triangle-1-s"></span>';
		header += '<div class="inline step"><strong>Step <span class="question-number">'+n+'</span>:</strong></div> ';
		header += stepText;
	}
	console.log("question header called with n="+n+" returning:"+header);
	return header;
}


function addAnswer(questionDOM){

	 //console.log("questionDOM passed: "+questionDOM);

	if($(questionDOM).hasClass('screener-question')){
		//clone answer template
		//swap in dynamic question number
		//swap in dynamic question number + 1
		//swap in dynamic answer number
//		console.log("question children li.answerRow: "+$(questionDOM).find('li.answerRow').length);

		//get parent question number
		var questionOrder  = $(questionDOM).find('.order').val()-1;

		if(questionOrder <= 0){
			questionOrder = 0;
		}

		var newAnswer = $('#answerTemplate').clone().html()
						.replace(/\[n\]/g, questionOrder)
						.replace(/\[n\+1\]/g, questionOrder+1)
						.replace(/\[i\]/g, $(questionDOM).find('li.answerRow').length)
						.replace(/\[i\+1\]/g, $(questionDOM).find('li.answerRow').length+1);

		//inject cloned new answer into question dom that was passed.
		//console.log("new answer html: "+$(newAnswer).prop("outerHTML"));

		$(questionDOM).find('ul.answersHolder')
						.first()
						.append( newAnswer );

		//fill the newly created answer's goto question dropdown with current list of questions
		var questionOptions = $('.questionSelect:first').html();
		$(questionDOM).find('.questionSelect').last().html(questionOptions);

	}

	//return passed in dom element with modifications
	return $(questionDOM);

}

function resetMultipleChoiceAnswers(questionDOM){
	$(questionDOM).find('ul.answersHolder li.answerRow:gt(1)').remove();
	$(questionDOM).find('.answerLabel').val('');
	$(questionDOM).find('.answerThen').val($('.answerThen option:first').val());
	//$(questionDOM).find('ul.answersHolder li.answerRow .answerThen').value(1).click();
}

function reportTableFormatter(){
	//once dom is loaded for report tables, this function adds the necessary styles.
	$('table.referral-status tbody td[rowspan]').parent().addClass('group-start');
	$('table.referral-status tbody td[rowspan]').parent().prev('tr').addClass('group-end');
	$('table.referral-status tbody td[rowspan]').addClass('status');


	$( 'table.referral-status tbody td[rowspan]' ).each(function( index ) {
	  $(this).addClass($(this).html().toLowerCase());
	});
	//each td that has a rowspan property, make its parent tr have group-start class
	//if there was another tr before the parent tr, add group-end.
	//when finished add group-end to last tr
	$('table.referral-status tbody tr').last().addClass('group-end');
}

function loadSiteTransferDropdownValues(campaign_id){
	//call
	$.ajax({
			  type: "POST",
			  url: '/ajax/campaignOptins/'+campaign_id,
			  dataType: 'json'
		})
		.done(
			function(data){
				if(data){
					//successful validation. hide notification area
					//iterate result and fill dropdowns
					var transferOptions = $("<select></select>");
					$(transferOptions).append("<option value='0'>Select a Site</option>");

					$.each(data, function(i, row) {
					  $(transferOptions).append("<option value='"+row.optin_id+"'>"+row.site_name+"&nbsp;&nbsp;&mdash;&nbsp;&nbsp;[Study: "+row.study_name+"]</option>");
					});

					$('.sites_in_campaign_dropdown').html($(transferOptions).html());

					//values are loaded into dropdowns. check each dropdown to set it to current selected value if it has one
					$( '.sites_in_campaign_dropdown' ).each(function( index ) {
						$(this).val(
										$(this).siblings('.transfer_optin_id_selected').first().val()
									);
					});

				}
				else{
					//we did not pass validation. output messages and scroll to the top

					$('.alerts').hide();
					$('.alerts').html("There was a problem fetching campaign data.");
					$('html, body').animate({ scrollTop: 0 }, 0, function(){ $('.alerts').fadeIn(); } );

				}
			}
		);

}

function saveOptinEmailMessage(){
	//create ajax post to our ajax route

	var study_id = $('#archive_filter_study').length > 0 ? $('#archive_filter_study option:selected').val() : $('#emailer_filter_study option:selected').val();

	$.ajax({
			  type: "POST",
			  url: '/ajax/saveOptinEmailForStudy/'+study_id,
			  dataType: 'json',
			  data: {
			  			'sender': $('#sender').val(),
				  		'subject': $('#subject').val(),
				  		'message': $('#message').val()
				  	}
		})
		.done(
			function(data){
				if(data.success.length || data.error.length){
					$('#confirmation-message-holder').html(data.success+data.error)
					.delay(1000)
					.fadeOut("slow", function(){
						$('#confirmation-message-holder').html('');
						$('#confirmation-message-holder').show();
					});

				}
			}
		);


	//load response in message area
}

function markOptinSent(optinId){
	//create ajax post to our ajax route
	$.ajax({
			  type: "POST",
			  url: '/ajax/markOptinSent/'+optinId,
			  dataType: 'json'
		})
		.done(

		);
}

$(function() {

	$('body').on('click', 'a.removeParentRow', function(e){
		e.preventDefault();
		//remove entire parent row from front end display
		$(this).parents("tr").first().fadeOut( 500, function() {
			$(this).remove();
		});
	});

	//save email functionality on optin archive and emailer pages
	$('body').on('click', '#save-email', function(e){
		e.preventDefault();
		saveOptinEmailMessage();
	});

	$('body').on('click', '#copyClickToCall', function(e){
		e.preventDefault();

		$('#clickToCallCode').select();

	    // Work around Chrome's little problem
	    $('#clickToCallCode').mouseup(function() {
	        // Prevent further mouseup intervention
	        $('#clickToCallCode').unbind("mouseup");
	        return false;
	    });

	    copyToClipboard();
	});

	$('.accordion').accordion({heightStyle: 'content', collapsible: true});

	reportTableFormatter();

  $( "#datepicker" ).datepicker({
      dateFormat: "mm-dd-yy",
  });
  $( "#datepicker2" ).datepicker({
      dateFormat: "mm-dd-yy",
  });
  $( "#datepicker3" ).datepicker({
      dateFormat: "mm-dd-yy",
  });
  $( "#datepicker4" ).datepicker({
      dateFormat: "mm-dd-yy",
  });

  $( "#reportStart" ).datepicker({
      dateFormat: "mm-dd-yy",
      altFormat: "yy-mm-dd",
      altField: "#altReportStart"
  });

  $( "#reportEnd" ).datepicker({
      dateFormat: "mm-dd-yy",
      altFormat: "yy-mm-dd",
      altField: '#altReportEnd'
  });

	$('select.linkTo').change(function(){
		$(this).parents('form').submit();
	});

	if($('.whispersForm').length){
		getWhispersForm();
	}

	$(document).on('change', '.campaignSelect', function(){
		getWhispersForm();
		loadSiteTransferDropdownValues($(this).val());
	});

	//BEGIN Screener page handlers and initializers
	//=======================================================================================
	$("#accordion").accordion({
		collapsible: true,
		active: false,
		heightStyle: "content"
	});

	//instantiate the Uploadifive uploaders
	$('.uploadifive').not('#templates .uploadifive').each(function(index){
		instantiateUploader($(this));
	});

	//	setup screener js handlers...()

	//if this is the create screener page, call addQuestion to add first question to screener.
	if($('#create-screener-button').length){
		addQuestion();
	}

	//Add question handler
	$('body').on('click', '#addQuestion', function(e){
		e.preventDefault();
		addQuestion();
	});

	//Delete Question handler
	$('body').on('click', '.deleteQuestion:not(#templates .deleteQuestion)', function(e){
		e.preventDefault();
		//get question we are within
		var question = $(this).parents('.screener-question').first();

		//must delete the h6 element created by accordion plugin:
		$(question).prev('h6').remove();
		$(question).remove();
	});

	//Add Answer handler
	$('body').on('click', '.addAnswer', function(e){
		e.preventDefault();

		//get question we are within
		var question = $(this).parents('.screener-question').first();
		//pass the question DOM to addAnswer so it knows where to add the answer

		newQuestion	= addAnswer($(question));
		//set behavior of new answer row-- simple or full answer
		if($(question).find('.questionThen').first().val() == 2){
			$(newQuestion).find('li.answerRow').last().find('.dependsOnResponse').fadeIn();
			$(newQuestion).find('li.answerRow').last().find('.answerThen').trigger('change');
		}
		else{
			$(newQuestion).find('li.answerRow').last().find('.dependsOnResponse').hide();
		}
	});

	//Check value of question type selction
	$(document).on('change', '.questionType', function(){
		var questionType = $(this).val();

		//the screener question that was acted upon
		var question = $(this).parents('.screener-question').first();

		console.log("question type is "+questionType);

		$(question).find('.dependsOnResponse').hide();

		if(questionType == 2){
			//Multiple choice
			$(question).find('.answersHolder').show();
			$(question).find('.addAnswer').fadeIn();

			console.log("showed answers stuff.");

			//enable "depends on response" action
			$(question).find(".questionThen option[value=2]").removeAttr('disabled');

			if( $(question).find('.questionThen').first().val() == 2){
				console.log("question then is DEPENDS ON RESPONSE.");
				$(question).find('.dependsOnResponse').fadeIn();
				$('.questionThen').trigger('change');

			}
			$(question).find(".questionThen option[value=2]").removeAttr('disabled');
		}
		else {
			//Message only - Does not Depend On Response
			$(question).find('.addAnswer').hide();
			$(question).find('.answersHolder').hide();
			$(question).find('.dependsOnResponse').fadeOut();
			//message-only question type cannot have next action of "depends on response" selected.
			//if we already had 'depends on response' selected, we have to default to the 'go to next question'.
			if($(question).find(".questionThen").val() == 2){
				$(question).find(".questionThen").val(1);
			}

			$(question).find(".questionThen option[value=2]").attr('disabled','disabled');

			if($(question).find('.questionThen').val() == 2){
				//we had a multiple choice
				$(question).find('.questionThen').val()
			};

			resetMultipleChoiceAnswers(question);
		}

		updateStepHeaders();
	});

	$(document).on('change', '.questionLabel', function(){
		updateStepHeaders();
	});

	//QUESTION NEXT ACTION
	$('body').on('change', '.questionThen', function(){
		//get Question Parent
		var question = $(this).parents('.screener-question').first();

		console.log("questionThen handler. value is "+$(this).val()+" question type value is "+$(question).find('.questionType').first().val());

		if($(this).val() == 2 &&  $(question).find('.questionType').first().val() == 2){
			//depends on response
			//&& multiple choice
			$(question).find('.answersHolder').show();
			$(question).find('.dependsOnResponse').fadeIn();

			//trigger answer actions
			$(question).find('.answerThen').trigger('change');

		}
		else{
			$(question).find('.dependsOnResponse').fadeOut();
		}

		if($(this).val() == 5 || $(this).val() == 6){
			$(question).find('.siteSearch').fadeIn();
		}
		else{
			$(question).find('.siteSearch').fadeOut();
		}

		updateStepHeaders();
	});

	//Drop down menu 'answerThen' functionality
	$('body').on('change', '.answerThen', function(){
		//get Question Parent
		var answer = $(this).parents('li.answerRow').first();

		switch($(this).val()){
			case 1:
			case '1':
					console.log("answer action: 1");
					//goto question number action.
					//show question number selection, hide transfer options
					$(answer).find('.dependsOnTransfer').hide();
					$(answer).find('.dependsOnTransferOut').hide();
					$(answer).find('.dependsOnNextQuestion').fadeIn();

				break;

			case 2:
			case '2':
					console.log("answer action: transfer call 2");
					//transfer call
					//show transfer controls
					$(answer).find('.dependsOnNextQuestion').hide();
					$(answer).find('.dependsOnTransferOut').hide();
					$(answer).find('.dependsOnTransfer').fadeIn();

					/*
$(answer).find('.transfer-phone').trigger('change');
					$(answer).find('.transfer-study').trigger('change');
*/

				break;

			case 6:
			case '6':
					console.log("answer action: transfer call action 6");
					$(answer).find('.dependsOnNextQuestion').hide();
					$(answer).find('.dependsOnTransferOut').hide();
					$(answer).find('.dependsOnTransfer').fadeIn();

					/*
$(answer).find('.transfer-phone').trigger('change');
					$(answer).find('.transfer-study').trigger('change');
*/
				break;

			case 7:
			case '7':
					console.log("answer action: transfer call action 7");
					$(answer).find('.dependsOnNextQuestion').hide();
					$(answer).find('.dependsOnTransfer').hide();
					$(answer).find('.dependsOnTransferOut').fadeIn();
				break;

			default:
					console.log("answer action: defaul");

					$(answer).find('.dependsOnTransfer').hide();
					$(answer).find('.dependsOnTransferOut').hide();
					$(answer).find('.dependsOnNextQuestion').hide();
				break;
		}

	});

	$('body').on('change', '.transfer-phone', function(){
		//get Question Parent
		var question = $(this).parents('.screener-question').first();

		$(question).find('.dependsOnResponse').show();

		console.log("transfer-phone triggered "+$(this).val()+"$(question).id "+$(question).attr('id'));

	});

	$('body').on('change', '.transfer-study', function(){
		console.log("transfer-study triggered "+$(this).val());
		var answer = $(this).parents('.answerRow').first();
		switch($(this).val()){
			case 1:
			case '1':
				//TRANSFER TO SITE
					$(answer).find('.dependsOnXtoNumber').hide();
					$(answer).find('.dependsOnTransfer').show();
					$(answer).find('.dependsOnXtoSite').fadeIn();
				break;

			case 2:
			case '2':
				//TRANSFER TO NUMBER
					$(answer).find('.dependsOnXtoSite').hide();
					$(answer).find('.dependsOnTransfer').show();
					$(answer).find('.dependsOnXtoNumber').fadeIn();
				break;
		}
	});


	//if this is the 'edit' screener page, perform all the hide/show js events.
	if($('#edit-screener-btn').length){
		console.log("triggering events now.");
		$('.twilio-form .questionType').trigger('change');
		$('.twilio-form .questionThen').trigger('change');
	}
	//END Screener page handlers and initializers
	//=======================================================================================

	$('.ajaxForm').submit(function(event){
		//stop form from submitting until we validate
		event.preventDefault();

	   var ajaxForm = this;

	   console.log($(this).serialize());

	   //post to validator
		$.ajax({
			  type: "POST",
			  url: $(this).attr('action'),
			  dataType: 'json'
		})
		.done(
			function(data){
				if(data.success){
					//successful validation. hide notification area
					$('.alerts').hide();
					$('.alerts').html('<p class = "success b-round">'+data.success+'</p>');
					$('.alerts').fadeIn();

					if(data.callbackUrl){
						window.location.href = data.callbackUrl;
					}
				}
			});
	});

	// ajax-validated forms
	//=======================================================================================
	//set up ajax validate form handlers function call...()
	$('.ajaxValidate').submit(function(event){
		//stop form from submitting until we validate
		event.preventDefault();

	    var ajaxForm = this;

		//get the button that triggered the ajax validate submit... clone or save?
		var submittedButton = $(".clickedButton");

		//if we are submitting a clone rather than a save, change the form action to create.
		if($(submittedButton).hasClass('clone-submit')){
			//add "copy" to the screener title to avoid validation errors
			$( 'input[name="screener[name]"]' ).val( $( 'input[name="screener[name]"]' ).val() + " Copy");
			//add clone flag so on screener processing method we unset question and answer ids
			$('#twilio-edit').prepend("<input type='hidden' name='clone' value='1' />");
			//remove screener id so it's cloned, not updated.
			$('#screener_id').remove();

			$(ajaxForm).attr('action','/screeners/create');
		}

	   console.log($(this).serialize());
	   //post to validator
		$.ajax({
			  type: "POST",
			  url: '/ajax/validator',
			  data: {
				  		input: $(this).serialize(),
				  		target: $(this).attr('action')
			  		},
			  dataType: 'json'
		})
		.done(
			function(data){
				if(data.success){
					//successful validation. hide notification area
					$('.alerts').fadeOut();


					//submit the form
		            ajaxForm.submit();
				}
				else{
					//we did not pass validation. output messages and scroll to the top

					$('.alerts').hide();
					$('.alerts').html(data.messages);
					$('html, body').animate({ scrollTop: 0 }, 0, function(){ $('.alerts').fadeIn(); } );

				}
			}
		);

	});

	 $('button').on('click', function(){
		$('button').removeClass('clickedButton');
		$(this).addClass('clickedButton');
	 });

	$(".campaignSelect").trigger('change');

	/* run the rounds events */
	roundsEvents();

	updateStepHeaders();


	/* OPTIN FORM custom js (dont allow get_notification checkbox unless email is filled out)*/

	//add disabled to checkboxes as needed when form loads
	disableAndDeselectOtherFieldIfValidEmail($( "input[name='optin_form\\[backup\\]\\[email\\]']" ), $( "input[name='optin_form\\[backup\\]\\[get_notification\\]']" ));

	disableAndDeselectOtherFieldIfValidEmail($( "input[name='optin_form\\[backup2\\]\\[email\\]']" ), $( "input[name='optin_form\\[backup2\\]\\[get_notification\\]']" ));

	// if something is typed in email fields, allow get notification check box
	$( "input[name='optin_form\\[backup\\]\\[email\\]']" ).change(function(){
		disableAndDeselectOtherFieldIfValidEmail($( "input[name='optin_form\\[backup\\]\\[email\\]']" ), $( "input[name='optin_form\\[backup\\]\\[get_notification\\]']" ));
	});

	$( "input[name='optin_form\\[backup2\\]\\[email\\]']" ).change(function(){
		disableAndDeselectOtherFieldIfValidEmail($( "input[name='optin_form\\[backup2\\]\\[email\\]']" ), $( "input[name='optin_form\\[backup2\\]\\[get_notification\\]']" ));
	});

});//END DOM READY

function disableAndDeselectOtherFieldIfValidEmail(elementToTest, otherElement){
	if(isEmail($(elementToTest).val())){
		//field is not blank. enable other element
		$(otherElement).removeAttr("disabled");
	}
	else{
		$(otherElement).attr('disabled', 'disabled');
		$(otherElement).attr('checked', false);
	}

}

function isEmail(email) {
  var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  return regex.test(email);
}

//===============================================================================
//=================		CAMPAIGNS/ROUNDS JS			=============================
//===============================================================================
function roundsEvents(){

  /* click event for selecting study in campaign create */
  $('table#campaign-studies-table tr.click').on('click', function(){

  	/* rounds study table row click event if not active */
  	if($(this).hasClass('active') == false){
		$(this).addClass('active');
		$(this).find('input').prop('checked', true);
		var name = $(this).find('input').attr('datafld');
		var id = $(this).find('input').attr('value');
		$('#round-study-tags').append('<div datafld="'+id+'" class = "tag '+id +'">'+name+' <i class="fa fa-times-circle"></i></div>');
		return;
	}

	/* rounds study table row click event if active  */
	else if($(this).hasClass('active') == true){
		var name = $(this).find('input').attr('datafld');
		var id = $(this).find('input').attr('value');
		console.log($('#round-study-tags').find('.' + id).remove());
		$(this).removeClass('active');
		$(this).find('input').prop('checked', false);
		return;
	}
  });

  /* rounds study tag click event */
  $(document).on('click', '.tag', function(){
  	var id = $(this).attr('datafld');
  	$(this).remove();
  	$('table#campaign-studies-table tr.click').each(function(){
  		if($(this).find('input').attr('value') == id)
  		{
			$(this).removeClass('active');
			$(this).find('input').prop('checked', false);
		}
  	});
  });

  /* rounds study table downdown toggle event */
  $('.dropper').on('click', function(){

	if($(this).hasClass('active') == false)
	{
		$(this).addClass('active');
		$('#collaps').slideDown();
		$(this).find('i').remove();
  		$(this).append('<i class="fa fa-chevron-up"></i>');
  		return;
	}

	else if($(this).hasClass('active') == true)
	{
		$(this).removeClass('active');
		$('#collaps').slideUp();
		$(this).find('i').remove();
  		$(this).append('<i class="fa fa-chevron-down"></i>');
  		return;
	}

  });

	var $rows = $('#campaign-studies-table tr.click');
	$('.rounds-search').keyup(function() {

		var val = $.trim($(this).val()).replace(/ +/g, ' ').toLowerCase();
	    $rows.show().filter(function() {
	        var text = $(this).text().replace(/\s+/g, ' ').toLowerCase();
	        return !~text.indexOf(val);
	    }).hide();
	});
}

$(document).ready(function() {

	$('body').on('click', '.donut_type', function() {
		switch ($(this).data('type')) {
			case 'percent':
				$('#donut_number').hide();
				$('#donut_percent').show();
				break;

			case 'number':
				$('#donut_number').show();
				$('#donut_percent').hide();
				break;

			default:
		}
		donutContainerSizing();
		$(this).closest('.btn-group').find('.dropdown-toggle').text($(this).text());
	});

	$('body').on('click', '.bar_type', function() {
		switch ($(this).data('type')) {
			case 'percent':
				$('#bar_number').hide();
				$('#bar_percent').show();
				break;

			case 'number':
				$('#bar_number').show();
				$('#bar_percent').hide();
				break;

			default:
		}

		$(this).closest('.btn-group').find('.dropdown-toggle').text($(this).text());
	});

	if ($('#statusSourceTable').length > 0) {
		$('#statusSourceTable td[rowspan]').addClass('status-cell');
	}

	$('#donut_number').hide();
	$('#bar_number').hide();

	donutContainerSizing();

	$(window).resize(function() {
		donutContainerSizing();
		quickStatsIconsSizing();
	});

	$('.tablesorter').each(function() {
		// if any columns in the tablesorter table have 'no-sort' class,
		// tell tablesorter plugin to not sort that column (by col index)
		var headersConfObj = {};

		$(this).find('th.no-sort').each(function(i){
			//console.log("found a no sort column on index i: "+i);
			headersConfObj[i] = {sorter:false};
		});
		// console.log("headers object: ");
		// console.dir(headersConfObj);

		$(this).tablesorter(
			{
				headers : headersConfObj
			}
		);
	});

});

function donutContainerSizing() {
	if ($(window).width() > 768) {
		$('.chart.donut').each(function() {
			$(this).parent().height( $(this).outerHeight() )
		});
	}
	else {
		$('.chart.donut').each(function() {
			$(this).parent().attr( 'style', '' )
		});
	}
}

function quickStatsIconsSizing() {
	if ($(window).width() > 962) {
		$('.quickStats .icons li').each(function() {
			$(this).height( $(this).width() );
		});
	}
}


//window._ = require('lodash');
window._ = require('underscore');

try {
    window.$ = window.jQuery = window.jquery = require('jquery');

    require('bootstrap');

    require('backbone');

    window.moment = require('moment');

    require('eonasdan-bootstrap-datetimepicker');

} catch (e) {}

//window.axios = require('axios');

//window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Register the CSRF Token as a common header with Axios and jQuery so that
 * all outgoing HTTP requests automatically have it attached.
 */

let token = document.head.querySelector('meta[name="csrf-token"]');

if (token) {

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    //window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;

} else {

    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');

}

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo'

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: 'your-pusher-key'
// });
